import React from 'react'
import { Grid } from '@material-ui/core'
import ProductCard from './ProductCard'

export default function ProductCards({ productList = [] }) {
    return (
        <div>
            <Grid container spacing={5}>
                {
                    productList.map((item, index) => {
                        return <Grid item key={item.id} xs={12}>
                            <ProductCard data={item} index={index} />
                        </Grid>
                    })
                }
            </Grid>
        </div>
    )
}
