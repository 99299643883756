import React from 'react'
import { Grid, Typography, makeStyles, Hidden } from '@material-ui/core'
import ImageComponent from '../GeneralComponents/ImageComponent'
import PrimaryButton from '../GeneralComponents/Buttons/PrimaryButton'
import SecondaryButton from '../GeneralComponents/Buttons/SecondaryButton'
import Routes from '../../utilities/Routes'
import LinkComponent from '../GatsbyComponents/LinkComponent'
import { contactFormId } from '../../utilities/Configurations'

const useStyles = makeStyles(theme => ({
    imageStyles: {
        width: '100%',
        maxHeight: "200px",
        borderRadius: '12px',
        objectFit: 'contain',
        [theme.breakpoints.up('md')]: {
            borderRadius: "8px",
            maxHeight: "400px",
        }
    },
    buttonStyles: {
        minWidth: "100%",
        [theme.breakpoints.up("sm")]: {
            minWidth: "180px"
        }
    },
    buttonContainer: {
        // width: '50%',
        // [theme.breakpoints.up("sm")]: {
        //     width: "auto"
        // }
    }
}))

function SoluctionCard({ data, flexDirection = 'row', contactLink }) {
    const { title, subtitle, excerpt, thumbnailImage, showDetailPage, slug } = data
    const classes = useStyles()
    return <Grid container alignItems='stretch' spacing={3} direction={flexDirection}>
        <Grid item xs={12} md={6}>
            <ImageComponent
                src={!!thumbnailImage ? thumbnailImage.url : ""}
                alt={!!thumbnailImage ? thumbnailImage.alternativeText : ""}
                className={classes.imageStyles}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <Grid container style={{ height: '100%' }} direction='column' justifyContent='space-between'>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {
                                (showDetailPage && !!slug) ?
                                    <LinkComponent path={`${Routes.solutions}/${slug}`}>
                                        <Typography variant='h3' color='textSecondary' >{title}</Typography>
                                    </LinkComponent>
                                    :
                                    <Typography variant='h3' color='textSecondary' >{title}</Typography>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5' color='textPrimary' >{subtitle}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1' color='textPrimary' style={{ whiteSpace: 'pre-line' }} >{excerpt}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} alignItems='center' style={{ marginTop: "10px" }}>
                        <Grid item xs={6} sm={4} className={classes.buttonContainer}>
                            <PrimaryButton className={classes.buttonStyles} href={contactLink}>
                                Get Quote
                            </PrimaryButton>
                        </Grid>
                        {
                            showDetailPage && !!slug &&
                            <Grid item xs={6} sm={4} className={classes.buttonContainer}>
                                <SecondaryButton className={classes.buttonStyles}
                                    href={`${Routes.solutions}/${slug}`}
                                >
                                    Read More
                                </SecondaryButton>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    </Grid>
}

function SolutionCardFeatured({ data, index, contactLink }) {
    return (
        <div id={data.slug}>
            <Hidden mdUp implementation='css'>
                <SoluctionCard data={data} flexDirection={'row'} contactLink={contactLink} />
            </Hidden>
            <Hidden smDown implementation='css'>
                <SoluctionCard data={data} flexDirection={index % 2 === 0 ? 'row-reverse' : 'row'} contactLink={contactLink} />
            </Hidden>
        </div>
    )
}


export default SolutionCardFeatured