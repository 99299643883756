import React from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import ImageComponent from '../GeneralComponents/ImageComponent'
import PrimaryButton from '../GeneralComponents/Buttons/PrimaryButton'
import LinkButton from '../GeneralComponents/Buttons/LinkButton'
import Routes from '../../utilities/Routes'
import LinkComponent from '../GatsbyComponents/LinkComponent'


const useStyles = makeStyles(theme => ({
    container: {
        boxShadow: "0px 8px 24px rgba(42, 106, 226, 0.1)",
        borderRadius: "12px",
        textAlign: 'center',
        height: "100%",
        display: 'flex'
    },
    imageStyles: {
        width: '100%',
        height: '200px',
        maxHeight: "250px",
        borderRadius: "12px 12px 0px 0px",
        objectFit: 'cover'
    },
    buttonStyles: {
        minWidth: "180px"
    },
}))

function SolutionCardNonFeatured({ data, contactLink }) {
    const { title, excerpt, thumbnailImage, showDetailPage, slug } = data
    const classes = useStyles()
    return (
        <div id={data.slug} className={classes.container}>
            <Grid container direction='column'  >
                <Grid item>
                    <ImageComponent
                        src={!!thumbnailImage ? thumbnailImage.url : ""}
                        alt={!!thumbnailImage ? thumbnailImage.alternativeText : ""}
                        className={classes.imageStyles}
                    />
                </Grid>
                <Grid item style={{ flex: 1, display: 'flex' }}>
                    <div style={{ padding: '15px', flex: 1, display: 'flex' }}>
                        <Grid container direction='column' justifyContent='space-between' spacing={2}>
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {
                                            (showDetailPage && !!slug) ?
                                                <LinkComponent path={`${Routes.solutions}/${slug}`}>
                                                    <Typography variant='h5' color='primary' >{title}</Typography>
                                                </LinkComponent>
                                                :
                                                <Typography variant='h5' color='primary' >{title}</Typography>

                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='body1' color='textPrimary' style={{ whiteSpace: 'pre-line' }} >{excerpt}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2} direction='column' alignItems='center'>
                                    <Grid item >
                                        <PrimaryButton className={classes.buttonStyles} href={contactLink}>
                                            Get Quote
                                        </PrimaryButton>
                                    </Grid>
                                    {
                                        showDetailPage && !!slug &&
                                        <Grid item >
                                            <LinkButton className={classes.buttonStyles} href={`${Routes.solutions}/${slug}`}>
                                                Read More
                                            </LinkButton>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}


export default SolutionCardNonFeatured