import * as React from "react"
import Layout from '../components/Layout'
import Hero from "../components/Hero"
import { graphql } from 'gatsby'
import { arraySorter, returnTitleSubHeadingsAndImages } from '../utilities/helperFunctions'
import SectionTitleComponent from "../components/SectionTitleComponent"
import SolutionCards from "../components/SolutionCards"
import CaseStudyCards from '../components/CaseStudyCards'
import ClientsList from '../components/ClientsList'
import ProductList from '../components/ProductCards'
import generalCss from '../styles/generalCss'
import { contactFormId } from "../utilities/Configurations"

const IndexPage = ({ data }) => {
  const homePageContent = data.cms.brsHomeContent
  const clientsList = data.cms.brsClientsLists.length !== 0 ? data.cms.brsClientsLists[0].clientImages : []
  const productList = data.cms.brsProducts
  const heroData = returnTitleSubHeadingsAndImages(homePageContent)
  let featuredSolutions = arraySorter({ array: !!data.cms.featuredSolutions ? data.cms.featuredSolutions : [], isNumberValue: true, checkKey: "order" })
  let nonFeaturedSolutions = arraySorter({ array: !!data.cms.nonFeaturedSolutions ? data.cms.nonFeaturedSolutions : [], isNumberValue: true, checkKey: "order" })
  let featuredCaseStudies = !!data.cms.featuredCaseStudies ? data.cms.featuredCaseStudies : []
  let nonFeaturedCaseStudies = !!data.cms.nonFeaturedCaseStudies ? data.cms.nonFeaturedCaseStudies : []
  const SEOData = !!homePageContent.Seo ? homePageContent.Seo : {}
  return (
    <div>
      <Layout pageSeo={SEOData}>
        <Hero
          title={heroData.title}
          subHeadings={heroData.subHeadings}
          coverImageMobile={heroData.coverImageMobile}
          coverImageWeb={heroData.coverImageWeb}
          buttonTitle='Get an Estimate'
          buttonHref={`/#${contactFormId}`}
        />
        {/* <div className='mainContainer'>
          
        </div> */}
        {
          featuredSolutions.length !== 0 &&
          <SectionTitleComponent
            title='Our'
            coloredTitle='Solutions'
            sectionId='main-solutions'
          >
            <SolutionCards
              cardList={featuredSolutions}
              featured={true}
            />
          </SectionTitleComponent>
        }
        {
          featuredCaseStudies.length !== 0 &&
          <SectionTitleComponent
            sectionId='recent-case-studies'
            title='Recent'
            coloredTitle="Case Studies"
          // subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
          >
            <CaseStudyCards
              cardList={featuredCaseStudies}
              featured={true}
            />
          </SectionTitleComponent>
        }
        {
          nonFeaturedCaseStudies.length !== 0 &&
          <SectionTitleComponent
            sectionId='more-case-studies'
            title='More'
            backgroundColor={generalCss.sectionBlueBg}
            coloredTitle="Case Studies"
          // subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
          >
            <CaseStudyCards
              cardList={nonFeaturedCaseStudies}
              showViewAllButton={true}
              featured={false}
            />
          </SectionTitleComponent>
        }
        {
          productList.length !== 0 &&
          <SectionTitleComponent
            sectionId='products'
            title='Our'
            coloredTitle="Products"
          >
            <ProductList productList={productList} />
          </SectionTitleComponent>
        }
        {
          nonFeaturedSolutions.length !== 0 &&
          <SectionTitleComponent
            title='Other'
            coloredTitle='Solutions'
            sectionId='other-solutions'
          >
            <SolutionCards
              cardList={nonFeaturedSolutions}
              featured={false}
            />
          </SectionTitleComponent>
        }
        {
          clientsList.length !== 0 &&
          <SectionTitleComponent
            sectionId='clients'
            title='Join our list of clients'>
            <ClientsList
              clipList={true}
              clientList={clientsList}
            />
          </SectionTitleComponent>
        }
      </Layout>
    </div>
  )
}

export const query = graphql`
query HomePageQuery {
  cms {
    brsHomeContent {
      Heading1
      SubHeading1
      SubHeading2
      coverImageMobile {
        url
      }
      coverImageWeb {
        url
      }
      Seo {
        SCHEMA_TAG
        SEO_DESCRIPTION
        SEO_TITLE
      }
    }
    featuredSolutions: brsSolutions(where: {isHighlight: true}) {
      id
      order
      showDetailPage
      slug
      subtitle
      thumbnailImage {
        url
        alternativeText
      }
      excerpt
      title
      shortDetails
    }
    nonFeaturedSolutions: brsSolutions(where: {isHighlight: false}) {
      id
      order
      showDetailPage
      slug
      subtitle
      thumbnailImage {
        url
        alternativeText
      }
      excerpt
      title
      shortDetails
    }
    featuredCaseStudies: brsCaseStudies(
      where: {featureInHomePage: true, showInHomePage: true}
    ) {
      excerpt
      id
      slug
      title
      services
      thumbNailImage {
        url
        alternativeText
      }
    }
    nonFeaturedCaseStudies: brsCaseStudies(
      limit: 3
      where: {featureInHomePage: false, showInHomePage: true}
    ) {
      excerpt
      id
      slug
      title
      services
      thumbNailImage {
        url
        alternativeText
      }
    }
    brsClientsLists {
      clientImages {
        id
        clientImage {
          url
          alternativeText
          id
        }
      }
    }
    brsProducts {
      id
      title
      slug
      showDetailPage
      shortDetails
      coverImage {
        url
        alternativeText
      }
    }
  }
}
`

export default IndexPage
