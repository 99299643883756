import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import SolutionCardFeatured from './SolutionCardFeatured'
import SolutionCardNonFeatured from './SolutionCardNonFeatured'
import { contactFormId } from '../../utilities/Configurations'

const useStyles = makeStyles(theme => ({
    gridItem: {
        padding: "10px 0px 20px",
        [theme.breakpoints.up('sm')]: {
            padding: "40px 0px 50px"
        }
    },
    gridItem2: {
        flex: 1,
        padding: "10px",
        [theme.breakpoints.up('sm')]: {
            padding: "60px 20px"
        }
    }
}))

export default function SolutionCards({ cardList = [], baseUrl = '', featured = false }) {
    const classes = useStyles()
    const contactLink = `${baseUrl}/#${contactFormId}`
    return (
        <div>
            <Grid container spacing={featured ? 0 : 5} alignItems='stretch'>
                {
                    cardList.map((item, index) => {
                        if (featured) {
                            return <Grid item key={item.id} className={classes.gridItem}>
                                <SolutionCardFeatured data={item} index={index} contactLink={contactLink} />
                            </Grid>
                        }
                        return <Grid item key={item.id} xs={12} sm={6} md={4} >
                            <SolutionCardNonFeatured data={item} contactLink={contactLink} />
                        </Grid>
                    })
                }
            </Grid>
        </div>
    )
}
